<template>
	<div style="text-align: center;background-color: #EBEEF5;">
		<div class="sameWidth">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 20px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/tenderInfo' }">招标信息</el-breadcrumb-item>
				<el-breadcrumb-item>招标详情</el-breadcrumb-item>
			</el-breadcrumb>
			<div style="text-align: left;padding:40px;margin:10px;background-color: #fff;">
				<div>
					<div id="tenderDiv">
						<h3>{{tenderInfo.name}}</h3>
						<p>
							<span>项目编号：{{tenderInfo.number}}</span>
							<span>招标方式：{{tenderInfo.way}}</span>
						</p>
						<p>
							<span>发布时间：{{tenderInfo.release_time}}</span>
							<span>报名截止时间：{{tenderInfo.end_time}}</span>
						</p>
						<el-divider></el-divider>
					</div>
					<div v-html="tenderInfo.content" id="wangTable">
					</div>
					<div style="margin-top:50px;">
						<p v-for="(item,index) in tenderInfo.path" :key="index" style="margin:5px 0;">
							<!--
							<span>附件下载：</span>
								<span @click="loadFile(item.annex_path,item.annex_name)" style="color: grey;cursor: pointer;">{{item.annex_name}}</span>
							-->
							<span>附件下载：</span>
              <span style="cursor:pointer;color: #0D81D6;" @click="loadFile(item.annex_path,item.annex_name)">{{item.annex_name}}</span>
<!--              <a :href="loadFile(item.annex_path,item.annex_name)" download style="text-decoration: none;color: grey;">{{item.annex_name}}</a>-->
						</p>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<style>
	#wangTable img{max-width: 100%;}
	#wangTable table{
		width:100%;
		border-top:#DCDFE6 1px solid;
		border-left:#DCDFE6 1px solid;
	}
	#wangTable table td,#wangTable table th{
		border-bottom:#DCDFE6 1px solid;
		border-right:#DCDFE6 1px solid;
		padding: 10px;
		height: 25px;
		line-height: 25px;
	}
</style>
<script>
export default {
	name:'tenderInfoContent',
	data() {
		return {
			screenWidth:1300,
			tenderInfo:{
				number:'',
				way:0,
				release_time:'',
				end_time:'',
				content:''
			}
		}
	},
	methods: {
    loadFile(path,name) {
      console.log(name);
      return window.open(path,'_blank');
    }
  },
	mounted:function(){
			this.bus.$emit('headerImg',[{src:require('@/assets/images/bk/招标信息.jpg'),href:""}])
			var that=this;
			let tenderId = window.atob(window.atob(this.$route.query.id));
			this.$axios.post(this.baseUrl+'admin/tender/searchTender?tenderInfoId='+tenderId).then(response=>{
				if(response.data.code!='200'){
					that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
				}else{
					var info=response.data.data.res[0];
					this.tenderInfo={
						name:info.name,
						number:info.number,
						way:this.way[info.way-1].name,
						release_time:info.release_time,
						end_time:info.end_time,
						content:info.content,
						path:info.path
					}
				}
			}).catch(function (error) {window.console.log(error)});//ajax请求失败

			this.$axios.post(this.baseUrl+'admin/tender/visitAdd',{
				'tender_id':tenderId,
				'visit_ip':'',
				'visit_address':'',
			}).then(response=>{
					console.log(response);
			}).catch(function (error) {window.console.log(error)});//ajax请求失败

			this.screenWidth=document.documentElement.clientWidth;
			window.addEventListener('resize',()=>{
				this.screenWidth=document.documentElement.clientWidth;
			})
	},
	beforeDestroy () {
		localStorage.removeItem('tempData')
	}
}
</script>

<style scoped>
	#tenderDiv{
		border-radius: 5px;margin-top: 20px;
	}
	#tenderDiv span{display: inline-block;width: 400px;}
</style>
